import { useLocation } from 'react-router-dom'

import { IconButton, Menu, MenuListItem } from '@cutover/react-ui'
import { useToggleFilterPanel } from 'main/components/layout/layout-hooks'
import { useLanguage } from 'main/services/hooks'
import { FilterPanelToggleButton, SubHeader, SubHeaderSearch } from 'main/components/layout/shared/sub-header'
import { browserQueryStringToServerQueryObject } from 'main/components/shared/filter/filter-params'
import { useClearFilterState, useSetFilterState } from 'main/recoil/data-access'

export const RunbookTypesSubHeader = () => {
  const { t } = useLanguage('runbookTypes')
  const toggleFilterPanel = useToggleFilterPanel()

  const setSearchQuery = useSetFilterState('query')
  const clearSearchQuery = useClearFilterState('query')

  const setArchivedFilter = useSetFilterState('archived')
  const clearArchivedFilter = useClearFilterState('archived')

  const location = useLocation()
  const filters = browserQueryStringToServerQueryObject({ query: location.search })

  const handleSearch = (input?: string) => {
    if (input) {
      setSearchQuery(input)
    } else {
      clearSearchQuery()
    }
  }

  const handleArchiveClick = () => {
    if (filters?.archived) {
      clearArchivedFilter()
    } else {
      setArchivedFilter(true)
    }
  }

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        <FilterPanelToggleButton
          filterCount={undefined}
          onClick={() => toggleFilterPanel()}
          onKeyUp={() => {}}
          tip={t('subheader.filterPanel.tip')}
        />
        <SubHeaderSearch onSearch={handleSearch} />
      </SubHeader.LeftItems>
      <SubHeader.RightItems>
        <Menu
          trigger={
            <IconButton
              tertiary
              data-testid="sub-header-options-menu"
              label={t('subheader.moreOptions.label')}
              icon={'more-vertical'}
            />
          }
        >
          <MenuListItem
            label={
              filters?.archived ? t('subheader.moreOptions.showUnarchived') : t('subheader.moreOptions.showArchived')
            }
            icon={filters?.archived ? 'undo' : 'trash-o'}
            onClick={handleArchiveClick}
          />
        </Menu>
      </SubHeader.RightItems>
    </SubHeader.Items>
  )
}
