import { Layout } from 'main/components/layout'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import { RunbookTypesHeader } from './runbook-types-header/runbook-types-header'
import { RunbookTypesSubHeader } from './runbook-types-sub-header/runbook-types-sub-header'
import { RunbookTypesFilter } from './runbook-types-filter/runbook-types-filter'
import { RunbookTypesEditPanel } from './right-panels/runbook-types-edit-panel'

export const RunbookTypesLayout = () => {
  useDefaultLayout({
    filterPanel: true,
    rightNav: false,
    subHeader: true
  })

  return (
    <Layout
      header={<RunbookTypesHeader />}
      subHeader={<RunbookTypesSubHeader />}
      filter={<RunbookTypesFilter />}
      rightPanels={<RunbookTypesEditPanel />}
    />
  )
}
